.pager {
    clear: both;
    margin: 0!important;
    padding: 0 0 30px!important;

    li {
        background-image: none;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        display: inline-block !important;
        list-style-type: none;
        margin: 0 0 0 5px !important;
        padding: 5px 0 !important;
        -webkit-transition: all .2s ease-in-out;
           -moz-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;

        &:hover {
            -webkit-transform: translateY(-4px);
                    transform: translateY(-4px);
        }

        &.pager-current {
            background-color: #5bc6f8;
            border: 1px solid #5bc6f8;
            color: #fff;
            padding: 5px 10px !important;
        }

        a {
            padding: 7px 10px !important;
            border-radius: 5px;
            -webkit-transition: all .2s ease-in-out;
               -moz-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;

            &:hover {
                text-decoration: none;
            }

            &.active {
                color: #000;
            }
        }
    }

    .pager-ellipsis {
        padding: 5px 10px !important;
    }
}

.rasca-grande-RASCAIZQUIERDAINTERIORGRANDECONTEXTUAL, 
.rasca-grande-RASCAIZQUIERDAINTERIORGRANDE {
    position: absolute;
    left: 0;
    top: 2px;
    width: 50%;
    height: 100%;
    z-index: 1;
    .publi-noticia-RASCAIZQUIERDAINTERIORGRANDECONTEXTUAL, 
    .publi-noticia-RASCAIZQUIERDAINTERIORGRANDE {
        position: relative;
        height: inherit;
        flex-direction: row-reverse;
        display: flex;
        z-index: 1;
        img {
            position: -webkit-sticky;
            position: sticky;
            top: 55px;
            margin-right: 513px;
        }
    }
}

.rasca-grande-RASCADERECHAINTERIORGRANDECONTEXTUAL, 
.rasca-grande-RASCADERECHAINTERIORGRANDE {
    position: absolute;
    right: 0;
    top: 2px;
    width: 50%;
    height: 100%;
    z-index: 1;

    .publi-noticia-RASCADERECHAINTERIORGRANDECONTEXTUAL, 
    .publi-noticia-RASCADERECHAINTERIORGRANDE {
        z-index: 1;
        position: relative;
        height: inherit;
        display: flex;

        img {
            position: -webkit-sticky;
            position: sticky;
            top: 55px;
            margin-left: 513px;
        }
    }
}

.container-full-noticia {
    width: 100%;
    background-color: #fff;
    overflow: inherit;

    .rasca-izq-pequeño {
        width: 200px !important;
        height: auto;
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 0;
        background: #fff;

        .publi-noticia-RASCAIZQUIERDAINTERIORSTICKYCONTEXTUAL {
            height: 100%;

            img {
                position: -webkit-sticky;
                position: sticky;
                top: 65px;
            }
        }
    }

    article.noticia {
        // max-width: 1030px !important;
        margin: 0 auto;
        z-index: 2;
        position: relative;

        .info-izq {
            width: 200px !important;
            height: auto;
            flex-basis: 200px;
            flex-grow: 0;
            flex-shrink: 0;
            background: #fff;

            img {
                position: -webkit-sticky;
                position: sticky;
                top: 65px;
            }
        }

        .info-izq-contextual {
            width: 200px !important;
            height: auto;
            flex-basis: 200px;
            flex-grow: 0;
            flex-shrink: 0;
            background: #fff;

            .publi-noticia-RASCAIZQUIERDAINTERIORSTICKYCONTEXTUAL {
                height: 100%;

                img {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 65px;
                }
            }
        }

        .container-noticia-background {
            max-width: 1030px !important;
            padding-right: 30px;
            padding-left: 30px;
        }

        .info-der {
            width: 200px !important;
            height: auto;
            flex-basis: 200px;
            flex-grow: 0;
            flex-shrink: 0;
            background: #fff;

            img {
                position: -webkit-sticky;
                position: sticky;
                top: 65px;
            }
        }

        .info-der-contextual {
            width: 200px !important;
            height: auto;
            flex-basis: 200px;
            flex-grow: 0;
            flex-shrink: 0;
            background: #fff;

            .publi-noticia-RASCADERECHAINTERIORSTICKYCONTEXTUAL {
                height: 100%;

                img {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 65px;
                }
            }
        }
    }

    .rasca-der-pequeño {
        width: 200px !important;
        height: auto;
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 0;
        background: #fff;

        .publi-noticia-RASCADERECHAINTERIORSTICKYCONTEXTUAL {
            height: 100%;

            img {
                position: -webkit-sticky;
                position: sticky;
                top: 65px;
            }
        }
    }

    article.noticia {
        background: $color-white;
    }
}

//VIDEOS GRID 5
.video-grid-rw-5 {
    display: grid;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    margin-left: 20px;
}

//FORMATO DICCIONARIO
.rw-diccionario h2 {
    font-size: 1rem !important;
   // font-weight: 400;
    font-family: "Raleway", sans-serif
}

.rw-diccionario a {
    color: inherit;
    border-bottom: 1px solid rgba(102, 204, 255, 0.5);
    text-decoration: none;
}

.rw-diccionario a:hover {
    border-bottom: 1px solid #5bc6f8;
}

//END DICCIONARIO

/* Para pantallas extra grandes (5 videos por fila) */
@media (min-width: 1500px) {
    .video-grid-rw-5{
        grid-template-columns: repeat(5, 338px);
    }
}

/* Para pantallas grandes (4 videos por fila) */
@media (min-width: 1200px) and (max-width: 1499px) {
    .video-grid-rw-5 {
        grid-template-columns: repeat(4, 338px);
    }
}

/* Para pantallas medianas (3 videos por fila) */
@media (min-width: 900px) and (max-width: 1199px) {
    .video-grid-rw-5 {
        grid-template-columns: repeat(3, 338px);
    }
}

/* Para pantallas pequeñas (2 videos por fila) */
@media (min-width: 600px) and (max-width: 899px) {
    .video-grid-rw-5 {
        grid-template-columns: repeat(2, 338px);
    }
}

/* Para móviles (1 video por fila) */
@media (max-width: 599px) {
    .video-grid-rw-5{
        grid-template-columns: 1fr;
    }
}

//VIDEO GRID 2
.video-grid-rw-2 {
    display: grid;
    gap: 10px; /* Ajusta el espacio entre los videos según tus necesidades */
    justify-content: center; /* Centra la cuadrícula horizontalmente */
    padding: 10px 90px; /* Ajusta el relleno según tus necesidades */
    margin-left: auto; /* Centra la cuadrícula */
    margin-right: auto; /* Centra la cuadrícula */
}

@media (min-width: 900px) {
    .video-grid-rw-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px; /* Puedes ajustar el espacio entre videos según tus necesidades */
    }
}

/* Para pantallas medianas (2 videos por fila) */
@media (min-width: 600px) and (max-width: 899px) {
    .video-grid-rw-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px; /* Puedes ajustar el espacio entre videos según tus necesidades */
    }
}

/* Para móviles (1 video por fila) */
@media (max-width: 599px) {
    .video-grid-rw-2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px; /* Puedes ajustar el espacio entre videos según tus necesidades */
    }
}

//END GRID VIDEOS

.container-full-noticia.grande {
    article.noticia {
        padding-left: 0;
        padding-right: 0;

        .info-izq, 
        .info-der {
            display: none;
        }
    }
}

.altura-min {
    min-height: 90vh;
}

.bloque-noticias {
    .especial {
        background-color: #f4f4f4;
    }
}

.section-boilerplate a {
    color: inherit;
    border-bottom: 1px solid rgba(102, 204, 255, 0.5);
    text-decoration: none;
}

article {
    figure {
        position: relative;

        img {
            height: auto;
            max-width: 100%;
            -webkit-transition: all .3s;
               -moz-transition: all .3s;
                    transition: all .3s;

            &.cuadrada_mini {
                width: 100px;
            }

            &.noticia {
                width: 375px;
                aspect-ratio: auto 375 / 200;
                height: 200px;
            }
        }
    }

    .iconos {
        display: flex;
        flex-direction: column;
        left: 0;
        position:absolute;
        top: 0;
        height: 100%;
        flex-wrap: wrap;
    }
}

//NEW BRAND CSS
.body-new-brand {
    font-family: 'Pontano Sans', sans-serif;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
}

.header-new-brand {
    background-color: #000000;
    color: #ffffff;
    padding: 10px 0;
    max-width: 1000px;
    margin: 0 auto;
}

.header-content-new-brand {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-left: 5px;
    padding-right: 5px;
}

.header-left-new-brand {
    font-family: 'Pontano Sans', sans-serif;
    font-size: 18px;
    text-align: left;
    padding-left: 15px;
}

.header-right-new-brand {
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    text-align: right;
    padding-right: 15px;
}

.short-hr-new-brand {
    width: 45%;
    margin: 20px auto;
    border: 0.1px solid #cccc;
}

.container-general-new-brand {
    max-width: 1000px;
    margin: 0 auto;
    border: 2px solid #000000;
}

.container-new-brand {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 10px;
}

.episodes-new-brand {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.episode-new-brand {
    width: 24%;
    text-align: center;
    position: relative;
    padding-bottom: 5px;
}

.episode-new-brand img {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
}

.episode-title-new-brand {
    font-family: 'Playfair Display', serif;
    font-size: 16px;
    text-align: left;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 3px;
}

.episode-description-new-brand {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    color: #666666;
    text-align: left;
    line-height: 1.3;
    font-weight: 400;
}

.footer-new-brand {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff;
}

.footer-description-new-brand {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    color: #999999;
    text-align: left;
    font-style: italic;
    padding: 5px 10px 10px 10px !important;
    line-height: 1.2;
}

//FIN NEW BRAND CSS

//CAROUSEL DESPLAZAMIENTO
.carousel-container-rw {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.episodes-new-brand-rw {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.episode-new-brand-rw {
    min-width: 25%;
    text-align: center;
    position: relative;
    padding-bottom: 5px;
}

.episode-new-brand-rw img {
    padding: 5px;
    width: 245px;
    height: 180px;
}

.button-rw {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0,0,0,0.5);
    border: none;
    color: white;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
    z-index: 1; /* Asegura que los botones estén por encima de las imágenes */
}

.prev-rw {
    left: 10px;
}

.next-rw {
    right: 10px;
}

.swiper-container {
    overflow: visible; /* Permite que la primera diapositiva sea parcialmente visible */
}

.swiper-wrapper {
    display: flex;
    transition: transform 0.3s ease;
}

.swiper-slide {
    flex: 0 0 25%; /* Asegura que cada diapositiva ocupa el 25% del contenedor */
    text-align: center;
    box-sizing: border-box;
}

.swiper-container::before {
    content: '';
    width: 10%; /* Ajusta este valor según cuánto quieras que se vea de la primera ficha */
    flex-shrink: 0;
}

.swiper-pagination-bullet-active {
    background: #000 !important; /* Color de la bola de paginación activa */
}


// CSS PUBLICIDAD LATERAL NEW BRAND

.header-main-public-brand {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    color: white;
    background: #646464;
    text-align: center;
    padding: 7px;
    font-weight: lighter;
}

.header-second-public-brand {
    background: #000000;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 10px;
   }

.header-second-text-public-brand {
    color: white;
    font-size: 28px;
    text-align: right;
    font-family: 'Playfair Display', serif;
    padding-right: 12px;
    line-height: 1;
}

.line-break-new-brand {
    display: inline-block;
    padding-bottom: 3px;
}

.rounded-capt-public-brand {
    font-family: 'Playfair Display', serif;
    color: #000000;
   // border-radius: 50%;
    width: 80px;
    text-align: center;
    padding-left: 5px;
   // line-height: 50px;
    //margin-right: 20px;
   // font-size: 20px;
}

.circle-image-public-brand{
    width: 70px;
}

.body-public-brand {
    background-color: rgba(0,0,0,.15);
    font-family: 'Pontano Sans', sans-serif;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.80);
    padding-bottom: 5px;
    padding-top: 10px;
}

.body-public-brand li {
    margin-bottom: 0.5em;
    line-height: 1.4;
}

.body-public-brand a {
    color: inherit;
    text-decoration: none;
}

.body-public-description {
    font-family: 'Pontano Sans', sans-serif;
    font-size: 12px;
    text-align: left;
    color: #999999;
    margin-top: 10px;
    line-height: 1.2;
}

//END PUBLICIDAD LATERAL NEW BRAND

//NEW DESING ESPECIAL
.text-especiales-rw {
    font-family: 'Pontano Sans', sans-serif;
    font-size: 12px;
    text-align: left;
    color: #999999;
    margin-top: 10px;
    line-height: 1.2;
}

.especial-small-logo-rw {
    width: 100px;
    height: auto;
}

.especial-small-logo-img-rw {
    width: 100px;
    height: auto;
    margin-left: 180px;
    z-index: 2;
    opacity: 0.9;
    top: 0;
}

.side-images-rw {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.side-images-rw img {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.img-small-especial-rw {
    max-width: 100%;
    width: 300px;
    height: 200px;
    z-index: 1;
    display: block;
    margin: 0 auto;
}


.destacada,
.vertical {
    background-size: cover !important; 
    background-position: 50% !important; 
    position: relative;

    > a {
        bottom: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    a, a:hover {
        color: #fff;
    }

    > .entradilla {
        color: #fff;
        visibility: hidden;
        overflow-y: hidden;
        -webkit-transition: all 0.5s ease-in-out;
           -moz-transition: all 0.5s ease-in-out;
             -o-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
    }
}

.destacada {
    height: 365px;
}

.vertical {
    display: flex;
    flex-direction: column-reverse;
    height: 490px;
    margin: auto;
    max-width: 350px;
}

.breve {
    figure {
        min-width: 80px;
    }
    img{
        width: 80px;
    }
}

#bloque-noticias-r1 {
    background-position: 50% !important;
    background-size: cover !important;
    height: 40em;
    position: relative;

    a {
        color: $color-white;
    }

    > article {
        .autorPortada{
            color: $color-white !important;
            &.titulo{
                display: inline-block;
                margin: 3.5em 30px 0 105px;
            }
        }
        h3 a {
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 9998;

            > span {
                display: inline-block;
                margin: 5em 30px 0 54px;
                width: 90%;
            }

            &:hover span span {
                border-bottom: 1px solid white;
                text-decoration: none;
            }
        }
    }

    > .container {
        background-color: rgba(0,0,0,.308);
        padding-top: 1em;
        padding-bottom: 1em;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 9999;

        img {
            border-radius: 4px;
        }
        .breve {
            .autorPortada    {
                color: $color-white !important;
            }
        }
    }
}

.noticia {
    .subtitle {
        ul {
            padding-left: 17px;
        }
    }
    .cuerpo {
        a {
            color: inherit;
            border-bottom: 1px solid rgba(102,204,255,.5);
            text-decoration: none;
            
            &:hover {
                color: inherit;
                border-bottom: 1px solid rgba(102,204,255,1);
                text-decoration: none;
            }
        }

        u {
            border-bottom: 1px solid;
            text-decoration: none;
        }

        ul {
            padding-left: 17px;
        }
        .mobile{
            display: none;
        }
        blockquote {
            position: relative;
            font-family: 'Playfair Display', serif;
            font-size: 24px;
            text-align: center;
            font-style: italic;
            font-weight: 400;
            margin: 4em auto 2em;
            max-width: 350px;

            ::before {
                font-family: 'Playfair Display', serif;
                font-weight: 400;
                content: "”";
                color: rgb(91, 198, 248);
                font-size: 140px;
                font-style: normal;
                position: absolute;
                top: -110px;
                left: 40%;
            }

            p:last-child{
                font-style: normal;
            }
            &.twitter-tweet {
               display: none;
            }
            &.tiktok-embed{
                ::before{
                    display: none;
                }
            }
            
        }
    
        .sumario {
            border-left: none;
            border-top: 2px solid #5bc6f8;
            float: left;
            font-family: playfair display,serif;
            font-size: 24px;
            font-style: normal;
            margin: 1em 2em 1em 0;
            padding: 1em 0 0;
            width: 40%; 
        } 
        .sumario_dere {
            border-left: none;
            border-top: 2px solid #5bc6f8;
            float: right;
            font-family: playfair display,serif;
            font-size: 24px;
            font-style: normal;
            margin: 1em 0 1em 2em;
            padding: 1em 0 0;
            width: 40%; 
        }

        .ladillo {
            color: #59cdff;
            font-weight: bolder;
        }
        .new-anclas {
            background: #f5f4f4;
            padding: 15px;
            margin-bottom: 15px;
            h2, h3{
                font-size: inherit;
                position: relative;
                margin: 0 0 1rem;
                padding: 1rem 1rem 0 4rem;  
                &::before{
                    background-image: url(/build/images/rw-indice.jpg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 2rem;
                    height: 2rem;
                    content: "";
                    flex-shrink: 0;
                    position: absolute;
                    top: 0.8rem;
                    left: .8rem;
                    border-radius: 20px;
                }
            } 
            ul, ol {
                list-style: decimal;
                padding-left: 2em;           
                margin-bottom: 0;  
                li {                  
                    padding: 0px 5px 10px;
                    color: #202020;
                    font-weight: bold;
                    a { 
                        color: #000;
                        font-weight: 400;
                        text-decoration: none;
                        background-color: transparent;
                        border-bottom: none;
                        &:hover{
                            color: inherit;
                            border-bottom: 1px solid rgba(0,0,0,.15);
                            text-decoration: none;
                        }
                    } 
                }                              
            }
        }     
        .reasonWhy{
            white-space: nowrap;
            span{
                color: #fff;
            }
        }
        img {
            width: 100% !important;
            height: auto !important;
        }

        .text-small {
            font-size: $text-size-13;
        }
    
        .text-big {
            font-size: $text-size-19;
        }
    
        .text-huge {
            font-size: $text-size-25;
        }

        figure {
            margin: 1em auto;
            max-width: 100%;

            &.image-style-align-right {
                float: right;
                margin: 1rem 0 1rem 30px;
            }

            &.image-style-align-left {
                float: left;
                margin: 1rem 30px 1rem 0;
            }

            &.image-style-align-center {
                margin: 1rem auto 1rem;
            }
        }

        h2, h3, h4 {
            font-size: inherit;
        }

        .bloque-intertext {
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            flex-wrap: wrap;
            margin-right: 30px;
            margin-bottom: 1.5rem;

            h2 {
                width: 100%;
                border-top: 1px solid #e5e5e5;
                line-height: 0;
                margin: 14px 0 20px;

                > span {
                    background: #fff;
                    padding-right: 1em;
                }
            }

            figure {
                margin: 0 24px 0 0;
                flex: 0 0 120px;
            }

            a {
                border: 0;

                &:hover {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    color: inherit;
                    text-decoration: none;
                    transition: all 0.2s ease;
                }
            }
            .noticia-relacionada {
                h3 {
                    color: $color-dark;
                    font-family: $font-family-title;
                    font-size: 1.25rem !important;
                    margin-left: 0 !important;
                    width: 75% !important;
                }

                p a {
                    color: $color-dark;
                    font-family: $font-family-title;
                    font-size: 1.25rem !important;
                    margin-left: 0 !important;
                    width: 75% !important;
                    line-height: 24px;
                }
                p {
                    line-height: 24px;
                }
            }
        }
        .twitter-tweet{
            margin: 0 auto 16px !important;
            max-width: 337px !important;
            width: 75% !important;
        }
        .raw-html-embed {
            iframe {
                margin: 0 auto 16px !important;
                display: block;
            }
            .twitter-tweet{
                margin: 0 auto 16px !important;
                max-width: 337px !important;
                width: 75% !important;
            }
            .infogram-embed {
                max-width: 600px;
                height: auto;
                margin: 0 auto;
                iframe {
                    display:none;
                }
                iframe:first-child{
                    display:block;
                    width: 100% !important;
                }
            }
        }
        .embed-genial {
            position: relative;
            padding-bottom: 112.50%;
            padding-top: 0;
            height: 0;
            /*width: 80%;
            margin: 0 auto;*/
        }
        [id^="cp_widget"] {
            margin-top: 56.25% !important;
        }
        .raw-html-embed {
            [id^="cp_widget"] {
                margin-top: 0 !important;
            }
        }
        &.noticia-cortada {
            position: relative;
            max-height: 500px;
            overflow: hidden;
            .degradado {
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.8) 55%, rgba(255,255,255,0.6) 70%, rgba(255,255,255,0.4) 80%, rgba(255,255,255,0.2) 90%, rgba(0255,255,255,0) 100%);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                .degradado-content {
                    position: relative;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    .leer-mas {
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        background-color: $color-dark;
                        color: $body-bg;
                        font-family: $font-family-title-block;
                        padding: 2px 5px;
                        position: absolute;
                        top: 65%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        outline: none;
                        border-radius: 5px;
                        padding: 0.375rem 0.75rem;
                        border: 1px solid $color-dark;
                        &:hover {
                            background-color: $body-bg;
                            color: $color-dark;
                            a {
                                color: $color-dark;
                            }
                        }
                        a {
                            color: $body-bg;
                            border-bottom: none;
                        }
                    }
                }
            }
        }


        .galeria-container {
            figure {
                display: block;
                background: #000;
                width: 100%;
                height: auto;
                position: relative;
                overflow: hidden;
                padding: 50% 0 0 0;
                img {
                    width: auto !important;
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .galeria-container[data-ancho="16:9"] {
            figure {
                padding: 56.25% 0 0 0;
            }
        }
        .galeria-container[data-ancho="9:16"] {
            max-width: 325px;
            margin: 0 auto;
            .slider-item {
                max-width: 325px;
            }
            figure {
                padding: 177.78% 0 0 0;
            }
        }
    }
}

.cuerpo.soundCiteColor {
    .soundcite-loaded {
        background-color: #0146e9;
        color: #fff;
    }
    .soundcite-play:before {
        border-left: 0.75em solid #0197FF;
    }
}

.super-container {
    position: relative;
}

.edit-botones {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 2;
    .edit-container-botones {
        position: sticky;
        margin: 70px 0 350px;
        top: 15%;
        display: flex;
        flex-direction: column;
        .edit-inline-container, .edit-cms-container {
            a {
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                width: 87px;
                height: 68px;
                background: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                
                i {
                    color: #fff;
                    font-size: 22px;
                }
    
                &:hover {
                    border-bottom: none;
                    width: 87px;
                }
            }
        }
    }
}

.rrss-share {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;

    .rrss-share-container {
        position: sticky;
        margin: 350px 0;
        top: 65%;
        display: flex;
        flex-direction: column;

        a {
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            width: 48px;
            height: 48px;
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: #fff;
                font-size: 22px;
            }

            &:hover {
                border-bottom: none;
                width: 64px;
            }
        }
    }
}

.rrss-share.rrss-share-mobile {
    display: none;
}

.section-share {
    .tags {
        display: flex;

        a.social {
            background: #000;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;

            i {
                font-size: 22px;
                color: #fff;
            }
        }
    }
}

.section-brand {
    color: #999999;
    font-style: italic;
    figure {
        width: 35.14%;
    }
    figure.image-style-align-left {
        float: left;
        margin: 0 30px 0 0;
    }
}

.section-tags {
    clear: both;
}

figure.brand-autor {
    width: 53.13%;
}
figure.image-style-align-left.brand-autor {
    float: left;
    margin: 0 30px 1rem 0 !important;
}

.especial {
    .title p {
        text-align: left !important;
    }
    .footer {
        background-color: $color-dark;

        a {
            font-family: $font-family-title-block;

            &:hover {
                border-bottom: 0;
            }
        }
    }
}

.fecha-autor {
    font-family: $font-family-title-block;
    color: $color-light;

    .autor:before {
        content: "·";
        margin-right: 4px;
    }
}

.avatar {
    min-width: 80px;

    img {
        width: 80px;
        height: auto;
    }
}

.comillas {
    color: #d3d3d3;
    font-size: 140px;
    font-style: inherit;
    position: absolute;
    right: 0;
    top: -60px;

    &::before {
        content: "”";
    }
}

.noticia-podcast {
    p {
        font-size: $text-size-14;
    }
}

//videos en noticias
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.item-media {
    .image {
        figcaption {
            display: none;
        }
    }
}

.reportaje iframe, .podcast iframe, .noticia iframe, .especial iframe {
    max-width: 100%;
    margin-bottom: 1rem;
}

//video cincopa margen negativos en portada y 0 en body
[id^="cincopa"] , [id^="cp_widget"]{
    margin-top: -56.25% !important;
}
.raw-html-embed {
    [id^="cincopa"] {
        margin-top: 0 !important;
    }
}
[id^="cincopa"].btn_chromecast {
    margin-top: 0 !important;
}

.rtecenter {
    text-align: center;
}

/* .soundcite-loaded[texto]::after {
    display: inline;
    content: attr(texto);
}
 */
.soundcite-loaded {
    br {
        display: none;
    }
}

#form-noticia {
    .titular {
        .save {
            background: #000;
            border: 0;
            color: #fff;
            width: 87px;
            height: 68px;
            padding: 10px 15px;
            position: fixed;
            right: 0;
            top: 150px;
            -webkit-appearance: none;
            i {
                color: #fff;
                font-size: 22px;
            }
        }
    }

    #noticia_inline_titular_editor {
        text-align: center;
        p{
            color: #000;
            font-family: 'Playfair Display', serif;
            font-size: 2.25rem;
            font-weight: 700 !important;
            letter-spacing: .5px;
            line-height: 1.2;
            margin: 0;
            overflow: hidden;
            resize: none;    
            text-align: center;      
        }
        
    }

    #noticia_inline_subtitulo_editor {
        ul {
            padding-left: 17px;
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    .ck.ck-editor__editable_inline {
        padding: 0;

        & > :first-child {
            margin-top: 0;
        }
    }
}

.publi-noticia-RASCAIZQUIERDAINTERIOR, .publi-noticia-RASCADERECHAINTERIOR {
    img {
        position: relative !important;
        top: 0 !important;
    }
}

.container-full-noticia article.noticia .info-izq-contextual, 
.container-full-noticia article.noticia .info-der-contextual {
    display: none;
}

.container-full-noticia article.noticia.rascas {
    display: flex;
    max-width: 1230px !important;
    padding: 0;
}

.container-full-noticia article.noticia.rascas.dos-rascas {
    max-width: 1430px !important;
}

@media (max-width: 1430px) {
    .container-full-noticia {
        .rasca-izq-pequeño {
            display: none;
        }

        article.noticia {
            display: flex;
            // max-width: 1030px !important;
            padding-left: 30px;
            padding-right: 30px;

            &.pagina-integrada {
                display: block;
            }
        }

        .container-con-rascas {
            article.noticia {
                max-width: 1430px !important;
            }
        }

        .rasca-der-pequeño {
            display: none;
        }
    }

    .rasca-grande-RASCAIZQUIERDAINTERIORGRANDECONTEXTUAL, 
    .rasca-grande-RASCADERECHAINTERIORGRANDECONTEXTUAL, 
    .rasca-grande-RASCAIZQUIERDAINTERIORGRANDE, 
    .rasca-grande-RASCADERECHAINTERIORGRANDE {
        display: none;
    }

    .container-full-noticia.grande {
        article.noticia {
            .info-izq, .info-der {
                display: flex;
            }
        }
    }
    .container-full-noticia article.noticia .info-izq-contextual, 
    .container-full-noticia article.noticia .info-der-contextual {
        display: block;
    }
}

@media (max-width: 1260px) {
    .container-full-noticia {
        article.noticia {
            .container-noticia-background {
                .hr-vertical {
                    border-right: none;
                    flex: 100% !important;
                    max-width: 100% !important;
                }

                .relacionadas {
                    display: none !important;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .rrss-share {
        display: none;
    }
}

@media (max-width: 991px) {
    .noticia {
        .cuerpo {
            .sumario {
                margin: 1em 1em 1em 0;
            }
            .sumario_dere {
                margin: 1em 0 1em 1em;
            }
        }
    }

    .container-full-noticia {
        article.noticia {
            .info-izq, 
            .rasca-izq-pequeño, 
            .info-izq-contextual {
                display: none;
            }
        }
    }

    .container-full-noticia.grande article.noticia .info-izq {
        display: none;
    }
}

@media (max-width: 975px) {
    .container-con-rascas {
        .noticia {
            .hr-vertical {
                border-right: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .noticia {
        .cuerpo {
            .sumario {
                font-size: 20px;
                min-width: 150px;
            }

            .sumario_dere {
                font-size: 20px;
                min-width: 150px;
            }

            .mobile {
                display: block;
            }

            .desktop {
                display: none;
            }

            .bloque-intertext {
                .noticia-relacionada {
                    img {
                        width: 80px !important;
                        height: 60px !important;
                    }

                    h3 {
                        font-size: 16px !important;
                        margin: 0 !important;
                    }

                    p a {
                        font-size: 16px !important;
                        margin: 0 !important;
                        width: 100% !important;
                        line-height: 16px;
                    }

                    p {
                        line-height: 16px;
                    }
                }

                figure {
                    margin: 0 15px 0 0;
                    flex: 0 0 80px;
                }
            }
        }

        .relacionadas {
            figure {
                display: none;
            }
        }
    }

    #bloque-noticias-r1 {
        > article {
            .autorPortada.titulo { 
                margin: 5.5em 30px 0 105px;
            }

            h3 {
                a {
                    > span {
                        margin: 6.5em 30px 0 54px;
                    }
                }
            }
        }

        > .container {
            .row { 
                .col-sm-6:last-child{
                    display: none;
                }
            }
        }
        iframe.instagram-media{
            margin: 0 auto 16px !important;
        }
    }

    .container-full-noticia article.noticia .container-noticia-background {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .container-full-noticia article.noticia {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }
}

@media (max-width: 575px) {
    .noticia {
        .title {
            font-size: 28px;
            margin-bottom: 30px !important;
            padding-top: 30px !important;
        }

        .hr-vertical {
            border-right: none;
        }
    }

    #bloque-noticias-r1 {
        > article {
            .autorPortada.titulo { 
                margin: 9em 30px 0 58px;
            }
            h3 { 
                font-size: $text-size-30 !important;
                a {
                    > span {
                        margin: 12em 30px 0 30px;
                    }
                }
            }
        }
        > .container {
             display: none;
        }
        
    }

    .destacada {
        height: 245px;
    }

    .container-full-noticia {
        article.noticia {
            .info-der, .rasca-der-pequeño, 
            .info-der-contextual {
                display: none;
            }
        }
    }

    .rrss-share.rrss-share-mobile {
        display: block;
        position: fixed;
        z-index: 999998;
        background: #000;
        bottom: 0;
        top: unset;
        width: 100%;
        height: 65px;

        .rrss-share-container {
            position: relative;
            margin: 0;
            top: unset;
            display: flex;
            width: 100%;
            justify-content: center;
            flex-direction: row;

            a {
                width: 65px;
                height: 65px;
                i {
                    font-size: 28px;
                }
                &:hover {
                    width: 65px;
                }
            }
        }
    }
    .container-full-noticia.grande article.noticia .info-der {
        display: none;
    }
}

@media (max-width: 400px) {
    .noticia {
        .cuerpo {
            .sumario {
                width: 100%;
                float: unset;
                padding-top: 10px;
                margin: 2em 1em 2em 0;
            }

            .sumario_dere {
                width: 100%;
                float: unset;
                padding-top: 10px;
                margin: 2em 1em 2em 0;
            }

            .image_resized {
                width: 100% !important;
            }
        }
    }

    #bloque-noticias-r1 {
        > article {
            h3 {                
                a {
                    > span {
                        width: 80%;
                    }
                }
            }
        } 
    }
}
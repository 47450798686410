.icon-bar {
    background-color: #000;
    border-radius: 1px;
    display: block;
    height: 3px;
    width: 25px;
    -webkit-transition: all 0.2s ease-in-out;
       -moz-transition: all 0.2s ease-in-out;
         -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
}

.ib-mid {
    margin-top: 4px;
    width: 80%;
}

.ib-bot {
    margin-top: 4px;
    width: 60%;
}

.ib-white {
    background-color: #fff;
}

.logo-main {
    padding-top: 13px;
}

#header-sticky {
    background-color: #000;
    display: block;
    height: 58px;
    padding: 0 15px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10005;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.5s ease-in-out;
       -moz-transition: all 0.5s ease-in-out;
         -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;

    .separador {
        background-color: #fff;
        height: auto;
        margin: 0;
        width: 2px;
    }

    a {
        img {
            max-width: 136px;
            max-height: 28px;
        }
    }
    &.is-visible {
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }
}

.btn-menu-toggle {
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
        .icon-bar {
            background-color: #5bc6f8;
        }
    }

    &:focus {
        outline: none;
    }
}

#header-especial,
#header-reportaje,
#header-podcast {
    background-position: 50% 0%;
    background-size: cover;
    color: #fff;
    height: 70vh;

    hr {
        border-color: #fff;
        opacity: .3;
    }

    a {
        color: #fff;
    }

    .especial {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        #buscador {
            background: none;
            left: 50%;
            transform: translate(-50%, 0);
            #busqueda {
                background: transparent;
                color: #fff;
            }
        }
    }

    #summary {
        > div {
            overflow: hidden;
            -webkit-transition: all 0.5s ease-in-out;
               -moz-transition: all 0.5s ease-in-out;
                 -o-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
        }
    }

    #menu {
        color: #fff;
        max-width: initial !important;
        width: 100%;

        a {
            &:hover {
                color: #5bc6f8;
            }
        }

        .icon-bar {
            background-color: #fff;
        }

        .btn-menu-toggle:hover .icon-bar {
            background-color: #5bc6f8;
        }
    }
}

#bloque-noticias-a0 {
    position: absolute;
    z-index: 0;
    width: 100%;
    left: 0;
    top: 0;
    .a0-content {
        #header-superdestacada {
            background-position: 50% 0%;
            background-size: cover;
            color: #fff;
            height: 70vh;
            display: flex;
            flex-direction: column;
            justify-content: end;
            .especial {
                background: none;
                .container {
                    margin-bottom: 50px;
                    h3 {
                        a {
                            color: #fff;
                        }
                    }
                }
            }
            #noticias-superdestacada {
                background: #fff;
                position: relative;
                padding-top: 30px;
            }
        }
    }
}

#bloque-noticias-a0-height {
    height: 412px;
}

.feedly {
    a {
        img {
            width: 20%;
        }
    }
}

.autor-cont {
    img {
        border-radius: 50px;
    }
}

#busqueda::-webkit-search-cancel-button{
    display: none;    
}

#switch-resumen, #switch-resumen-sticky {
    width: 234px;
    padding-top: 25px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
         transition: all .3s;
    transform: translateX(19px);
    .switch {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 30px;
    }
      
    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
      
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #b3b3b3;
        -webkit-transition: .4s;
        transition: .4s;
    }
      
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 8px;
        bottom: 5px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
      
    input:checked + .slider {
        background-color: #00ba36;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px #00ba36;
    }
    
    input:checked + .slider:before {
        -webkit-transform: translateX(36px);
        -ms-transform: translateX(36px);
        transform: translateX(36px);
    }
    
    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
    .text {
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
             transition: all .3s;
        text-transform: uppercase;
        position: absolute;
        left: 90px;
        top: -3px;
        width: 144px;
        color: #b3b3b3;
        line-height: 1.2;
    }
}

#switch-resumen-sticky {
    position: absolute;
    right: 20px;
    padding-top: 13px;
    .text {
        color: $color-white;
    }
}

.resumen-boton, .resumen-boton-sticky {
    padding-top: 30px;
    a {
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
             transition: all .3s;
        text-transform: uppercase;
        color: #b3b3b3;
        line-height: 1.2;
        font-weight: 600;
        &:hover {
            border-bottom: 1px solid #00ba36;
        }
    }
}

.resumen-boton-sticky {
    position: absolute;
    right: 20px;
    padding-top: 17px;
    a {
        color: $color-white;
        &:hover {
            color: $color-white;
        }
    }
}

//MOVILES SWITCH
#switch-resumen, #switch-resumen-mobile {
    width: 234px;
    padding-top: 25px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    transform: translateX(19px);
    .switch {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 30px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #b3b3b3;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 8px;
        bottom: 5px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #00ba36;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #00ba36;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(36px);
        -ms-transform: translateX(36px);
        transform: translateX(36px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .text {
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
        text-transform: uppercase;
        position: absolute;
        left: 90px;
        top: -3px;
        width: 144px;
        color: #b3b3b3;
        line-height: 1.2;
    }
}

#switch-resumen-mobile {
    position: absolute;
   // right: 20px;
    padding-top: 40px;
    .text {
        color: $color-white;
    }
}

.resumen-boton, .switch-resumen-mobile {
    padding-top: 30px;
    a {
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
        text-transform: uppercase;
        color: #b3b3b3;
        line-height: 1.2;
        font-weight: 600;
        &:hover {
            border-bottom: 1px solid #00ba36;
        }
    }
}

header.superdestacada {
    z-index: 1;
    > .container {
        max-width: unset !important;
    }
    > hr {
        border-color: #fff;
        opacity: .3;
    }
    #menu {
        color: #fff;
        max-width: unset !important;
        .icon-bar {
            background-color: #fff;
        }
        a {
            color: #fff;
        }
    }
    #buscador {
        background: none;
        left: 50%;
        transform: translate(-50%, 0);
        #busqueda {
            background: transparent;
            color: #fff;
        }
    }
}

.no-header {
    #header {
        > .container, > hr, #menu {
            display: none;
        }
    }
}

.no-sticky {
    #header-sticky {
        display: none;
    }
}

.sticky-pagina {
    #header-sticky {
        .header-sticky-content {
            justify-content: center;
            #abrir-menu-toggle-sticky, .separador {
                display: none;
            }
        }
        a.logo-sticky {
            margin: 10px 0 0 !important;
            img {
                max-width: 208px;
                max-height: 40px;
            }
        }
    }
}

.sticky-pagina.no-header {
    #header-sticky {
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }
}

.pagina-sticky-pagina.pagina-no-header {
    margin-top: 58px;
}

@media (max-width: 991px) {
    #bloque-noticias-a0 {
        .a0-content {
            #header-superdestacada {
                height: auto;
                .especial {
                    height: 70vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }
        }
    }
    #noticias-superdestacada {
        .breve {
            margin-bottom: 20px !important;
        }
    }
}

@media (max-width: 575px) {
    .portada {
        .hr-header {
            display: none;
        }
    }
    #header-sticky {
        .header-sticky-content {
            margin-right: 43px;
            .logo-sticky {
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }
    .switch-mobile-rw {
        height: 130px !important;
    }
    .sticky-pagina {
        #header-sticky {
            .header-sticky-content {
                margin-right: 0;
            }
        }
    }
    #header-reportaje .especial {
        justify-content: flex-end !important;
        min-height: 69% !important;
        .title { 
            font-size: $text-size-30 !important;
            &.block{
                font-size: $text-size-20 !important;
            }
        }  
    }
    #header-superdestacada .especial {
        .title { 
            font-size: $text-size-30 !important;
            &.block{
                font-size: $text-size-20 !important;
            }
        }  
    }
    #bloque-noticias-a0-height {
        height: 740px;
    }
}

@media (max-width: 400px) {
    .noticia-menu {
        display: none;
    }
    .secciones {
        margin: 0 auto;
    }
}

label {
  font-weight: 600;
  margin: 0 0 0.5rem 0;
}

input {
  border-radius: 50px !important;
}

textarea {
  border-radius: 20px !important;
}

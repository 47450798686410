label {
    cursor: pointer;
    display: block;
    margin: 0 0.5rem 0 1rem;
    position: relative;
    z-index: 1;
}

#buscador {
    background: rgba(255, 255, 255, 1);
    border: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transform-origin: center right;
    transform: scaleX(0);
    transition-delay: 0ms, 0ms;
    transition: transform 100ms, opacity 100ms;
    top: -1.5rem;
    width: 100%;

    &:focus-within {
        opacity: 1;
        outline: 0;
        transform: scaleX(1);
        transition-delay: 100ms, 100ms;
        white-space: nowrap;
        z-index: 2;

        ~ label,
        ~ ul,
        ~ button {
            opacity: 0;
            transform: scale(0.5);
        }
    }

    input[type='search'] {
        border: 0;
        flex: 1;
        font-size: 24px;
        height: 70px;
        outline: none;
    }
}

#buscador-form {
    border: 1px solid #929292;
    border-radius: 20px;

    input[type='search'] {
        border: 0;
        outline: none;
        padding: 0.5em;
        width: 100%;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance:none;
        }
    }

    button {
        border: 0;
        background: transparent;
    }
}

.buscador-on {
    background-color: transparent !important;
    a {
        color: transparent !important;
    }
}
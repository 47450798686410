.reproductor {
  #hyperplayer {
    width: 100% !important;
    outline: none;
  }
}

@media (max-width: 575px) {
  .reproductor {
    margin: 0 !important;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    z-index: 1000000;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.75);
    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,.75);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.75);
    #hyperplayer {
      width: 90% !important;
    }
  }
}
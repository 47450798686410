.btn-white, 
.tags .tag {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #212529;
    border-radius: 20px;

    &:hover {
        background-color: #fafafa;
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

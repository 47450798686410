.mobile-banner {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.mobile-banner img {
    width: 100%;
    height: auto;
}

#cajadecontenido {
    overflow-x: hidden;
    max-width: 100%;
}

/* ajustes espesificaciones javier */
#rw-banner {
    position: fixed;
    top: 220px;
    right: 0;
    width: 100vw;/* ajustes tamano banner*/
    z-index: -1;
}




.rw-article-title-link {
    text-decoration: none;
    color: #000;
}

.rw-article-title-link:hover {
    color: inherit;
    text-decoration: none;
}
/* ajustes espesificaciones javier */
.rw-separator-principal {
    max-width: 80%;
    /* se elimino margen */
}

.rw-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

/* ajustes espesificaciones javier */
.rw-title-special {
    font-size: 3.4rem; /*aumento de tamano fuente */
    font-weight: bold;
    margin-bottom: 10px;
    font-family: "Playfair Display", serif !important;
    line-height: 1.2;
    font-style: italic;
    color: #000;
}

.rw-parrafo-descriptivo {
    font-family: "Pontano Sans", sans-serif;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.rw-article-text {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    color: #5b5b5b;
}

/* ajustes espesificaciones javier */
.rw-article-title {
    font-size: 1.37rem; /*aumento de tamano fuente */
    margin-bottom: 1rem;
    font-family: 'Playfair Display', serif;
}

.rw-fecha-autor {
    font-size: 0.75rem;
    color: #999;
    margin-bottom: 0 !important;
    font-family: "Oswald", sans-serif;
}

.rw-fecha-autor .autor:before {
    content: "·";
    margin-right: 4px;
}


/* ajustes espesificaciones javier */
.rw-first-colum-div {
    margin-left: 40px;
    margin-right: 40px;
    max-width: 380px; /*aumento de tamano elemento */
}




/* Media Queries */


/* Display Mobile Banner on Small Screens */
@media (max-width: 900px) {
    .mobile-banner {
        display: block;
    }
    .rw-parrafo-descriptivo p {
        display: none;
    }
}



/* Adjustments for 1200px to 1445px */
@media (min-width: 1200px) and (max-width: 1445px) {
    .rw-first-colum-div {
        margin-left: 40px;
        margin-right: 40px;
        min-width: 380px;
    }
    #rw-banner {
        padding: 44px !important;
    }
}

/* Adjustments for 1800px and above */
@media (min-width: 1800px) {
    .rw-first-colum-div {
        max-width: 450px;
    }
    #rw-banner {
        max-width: 300px;
        padding: 20px !important;
    }
    .rw-article-title {
        font-size: 1.375rem;
        line-height: 1.2;
    }
}

/* Adjustments for 2000px and above */
@media (min-width: 2000px) {
    .rw-first-colum-div {
        min-width: 530px;
    }
    #rw-banner {
        max-width: 12vw;
        padding: 20px !important;
    }
}





/* ajustes espesificaciones javier */
@media (max-width: 990px) {
    .rw-title-special {
        font-size: 38px;
        /* eliminado margen en celulares*/
    }
    #rw-content-margin-r {
        margin-right: 1.5rem;
    }
    .rw-title-special h1, .arena-logo, .rw-parrafo-descriptivo {
        display: none;
    }
    .rw-title-special h1 {
        display: block;
        text-align: center;
        font-size: 2.5rem;
    }
    .rw-article-section {
        padding: 10px;
        margin-bottom: 20px;
    }
    #rw-banner {
        display: none;
    }
    .rw-fecha-autor {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
    }
    .rw-article-title {
        font-size: 1.4rem;
        margin-top: 10px;
    }
    .rw-d-flex {
        flex-direction: column;
    }
}

/* Adjustments for 768px to 1200px */
@media (min-width: 768px) and (max-width: 1200px) {
    .rw-d-flex{
        flex-direction: column;
    }
}
@media (max-width: 1190px){
    #rw-banner{
        display: none;
    }
}

@media (min-width: 2200px) {
    .rw-article-title, .rw-parrafo-descriptivo {
        max-width: 100%;
        font-size: 1.4rem;
    }
}
.rw-d-flex{
    display: flex !important;
}

.rw-mb-4{
    margin-bottom: 1.5rem !important;
}

.rw-mt-5{
    margin-top: 3rem !important;
}



/* <!-- Ajustes toogle menu  2--> */


/* Estilos generales para desktop (todo visible) */
.rw-toggle-content {
    display: block;
}

.rw-toggle-arrow {
    display: none; /* Ocultar flecha en desktop */
}

/* Solo en versión móvil ocultamos el contenido inicialmente */
@media (max-width: 900px) {
    .rw-toggle-content, #cajadecontenido > div > div > div.rw-first-colum-div > p {
        display: none; /* Ocultar el contenido en móviles por defecto */
    }

    /* Estilo para el contenedor del título y la flecha en móviles */
    .rw-toggle-header {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Alinear a la izquierda */
    }

    /* Título en móviles */
    .rw-title-special {
        margin: 0;
    }

    .rw-toggle-arrow {
        display: inline-block;
        font-size: 1.5rem;
        text-align: left;
        transition: transform 0.3s ease;
        position: relative;
        margin-right: 10px; /* Espacio entre la flecha y la línea */
    }

    .rw-toggle-arrow::after {
        content: '';
        display: inline-block;
        width: 250px; /* Ajusta este ancho según sea necesario */
        height: 1px;
        background-color: #ccc;
        vertical-align: middle;
        margin-left: 8px; /* Espacio opcional entre la flecha y la línea */
    }



    /* Clase para mostrar el contenido en móviles */
    .rw-toggle-content.show,  #cajadecontenido > div > div > div.rw-first-colum-div > p.show{
        display: block;
        max-width: 100%;
        color: #5b5b5b;
        font-size: 1rem;
        font-family: "Pontano Sans", sans-serif !important;
    }
}

/* <!-- Ajustes toogle menu --> */

.portada{   
    .autorPortada {
        font-family: $font-family-title-block;
        color: $color-light; 
    } 
    .title p {
        text-align: left !important;
    }  
} 

#portada, #resumen-2021 {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
         transition: all .3s;
    visibility: hidden;
    opacity: 0;
    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.title-resumen {
    text-align: right;
    .year {
        font-family: $font-family-title-block;
        font-weight: $font-weight-bold;
        font-size: 3rem;
        display: block;
        color: $color-dark;
    }
    h1 {
        font-family: $font-family-title;
        font-style: italic;
        font-size: 4rem;
        display: block;
        color: $color-body;
    }
}

.bloque-html-home {
    .bloque-html-content {
        background: $color-dark;
        padding: 30px 0 80px;
        .title {
            color: $color-white;
        }
        .video-content {
            width: 90%;
            margin: 0 auto;
            .video-responsive {
                overflow:hidden;
                padding-bottom: 56.25%;
                position:relative;
                height:0;
                iframe {
                    left:0;
                    top:0;
                    height:100%;
                    width:100%;
                    position:absolute;
                }
            }
        }
    }
}

.especial {
    .icono-especial {
        position: absolute;
        right: 20px;
        top: -10px;
        a {
            &:hover {
                border-bottom: none;
            }
            img {
                width: 35px;
            }
        }
    }
    &.especial-vertical {
        .icono-especial {
            right: 5px;
        }
    }
}

.container-con-rascas {
    display: flex;
    max-width: 1430px !important;
    padding-right: 0;
    padding-left: 0;
    margin: 0 auto;
    .publi-noticia-RASCAIZQUIERDASTICKY, .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY {
        width: 200px !important;
        height: 100%;
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 0;
        img {
            position: -webkit-sticky;
            position: sticky;
            top: 65px;
        }
    }
    .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCAIZQUIERDAINTERIOR {
        width: 200px !important;
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    > .container {
        max-width: 1030px !important;
        padding-right: 30px;
        padding-left: 30px;
    }
    .publi-noticia-RASCADERECHASTICKY, .publi-noticia-RASCADERECHAINTERIORSTICKY {
        width: 200px !important;
        height: 100%;
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 0;
        img {
            position: -webkit-sticky;
            position: sticky;
            top: 65px;
        }
    }
    .publi-noticia-RASCADERECHA, .publi-noticia-RASCADERECHAINTERIOR {
        width: 200px !important;
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    &.container-con-un-rasca.con-un-rasca-izq {
        > .container {
            margin-left: 0;
        }
    }
    &.container-con-un-rasca.con-un-rasca-der {
        > .container {
            margin-right: 0;
        }
    }
}

.container-con-rascas {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .mb-4, .my-4 {
        margin-bottom: 1.5rem !important;
    }
    .d-none {
        display: none !important;
    }
}

@media (max-width: 1391px) {
    .container-con-dos-rascas {
        .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCAIZQUIERDASTICKY {
            display: none;
        }
        > .container {
            margin-right: 0;
        }
    }
}

@media (max-width: 1191px) {
    .container-con-rascas {
        .w-lg-350px {
            max-width: 350px;
        }
        .m-auto {
            margin: auto !important;
        }
        .mb-lg-30px {
            margin-bottom: 30px;
        }
        .mt-lg-30px {
            margin-top: 30px;
        }
    }
}

@media (max-width: 967px) {
    .container-con-rascas {
        .w-lg-350px {
            max-width: 350px;
        }
        .w-md-350px {
            max-width: 350px;
        }
        .m-auto {
            margin: auto !important;
        }
        .d-sm-none {
            display: none !important;
        }
        .mt-md-30px {
            margin-top: 30px;
        }
    }
}

@media (max-width: 991px) {
    .container-con-rascas {
        .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCAIZQUIERDASTICKY, .publi-noticia-RASCAIZQUIERDAINTERIOR, .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY {
            display: none;
        }
        .col-absolute-especial-lateral {
            position: relative;
            margin-top: 0;
        }
        .col-height-especial-lateral {
            height: auto;
            margin-bottom: 1.5rem;
        }
    }
    .container-con-un-rasca.con-un-rasca-izq {
        .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCAIZQUIERDASTICKY, .publi-noticia-RASCAIZQUIERDAINTERIOR, .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY {
            display: block;
        }
    }
}

@media (max-width: 575px) {
    .container-con-rascas {
        .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCAIZQUIERDASTICKY, .publi-noticia-RASCADERECHA, .publi-noticia-RASCADERECHASTICKY, .publi-noticia-RASCAIZQUIERDAINTERIOR, .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY, .publi-noticia-RASCADERECHAINTERIOR, .publi-noticia-RASCADERECHAINTERIORSTICKY {
            display: none;
        }
    }
    .container-con-un-rasca.con-un-rasca-izq {
        .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCAIZQUIERDASTICKY, .publi-noticia-RASCAIZQUIERDAINTERIOR, .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY {
            display: none;
        }
    }
}

@media (min-width: 976px) {
    .container-con-rascas {
        .col-sm-1 {
            flex: 0 0 8.33333% !important;
            max-width: 8.33333% !important;
        }
        .col-sm-2 {
            flex: 0 0 16.66667% !important;
            max-width: 16.66667% !important;
        }
        .col-sm-3 {
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
        .col-sm-4 {
            flex: 0 0 33.33333% !important;
            max-width: 33.33333% !important;
        }
        .col-sm-5 {
            flex: 0 0 41.66667% !important;
            max-width: 41.66667% !important;
        }
        .col-sm-6 {
            flex: 0 0 50% !important;
            max-width: 50% !important;
        }
        .col-sm-7 {
            flex: 0 0 58.33333% !important;
            max-width: 58.33333% !important;
        }
        .col-sm-8 {
            flex: 0 0 66.66667% !important;
            max-width: 66.66667% !important;
        }
        .col-sm-9 {
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }
        .col-sm-10 {
            flex: 0 0 83.33333% !important;
            max-width: 83.33333% !important;
        }
        .col-sm-11 {
            flex: 0 0 91.66667% !important;
            max-width: 91.66667% !important;
        }
        .col-sm-12 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .d-sm-flex {
            display: flex !important;
        }
        .d-sm-block {
            display: block !important;
        }
    }
}

@media (min-width: 968px) {
    .container-con-rascas {
        .col-md-1 {
            flex: 0 0 8.33333% !important;
            max-width: 8.33333% !important;
        }
        .col-md-2 {
            flex: 0 0 16.66667% !important;
            max-width: 16.66667% !important;
        }
        .col-md-3 {
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
        .col-md-4 {
            flex: 0 0 33.33333% !important;
            max-width: 33.33333% !important;
        }
        .col-md-5 {
            flex: 0 0 41.66667% !important;
            max-width: 41.66667% !important;
        }
        .col-md-6 {
            flex: 0 0 50% !important;
            max-width: 50% !important;
        }
        .col-md-7 {
            flex: 0 0 58.33333% !important;
            max-width: 58.33333% !important;
        }
        .col-md-8 {
            flex: 0 0 66.66667% !important;
            max-width: 66.66667% !important;
        }
        .col-md-9 {
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }
        .col-md-10 {
            flex: 0 0 83.33333% !important;
            max-width: 83.33333% !important;
        }
        .col-md-11 {
            flex: 0 0 91.66667% !important;
            max-width: 91.66667% !important;
        }
        .col-md-12 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .mb-md-0, .my-md-0 {
            margin-bottom: 0 !important;
        }
        .d-md-flex {
            display: flex !important;
        }
        .d-md-block {
            display: block !important;
        }
    }
}

@media (min-width: 1192px) {
    .container-con-rascas {
        .col-lg-1 {
            flex: 0 0 8.33333% !important;
            max-width: 8.33333% !important;
        }
        .col-lg-2 {
            flex: 0 0 16.66667% !important;
            max-width: 16.66667% !important;
        }
        .col-lg-3 {
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
        .col-lg-4 {
            flex: 0 0 33.33333% !important;
            max-width: 33.33333% !important;
        }
        .col-lg-5 {
            flex: 0 0 41.66667% !important;
            max-width: 41.66667% !important;
        }
        .col-lg-6 {
            flex: 0 0 50% !important;
            max-width: 50% !important;
        }
        .col-lg-7 {
            flex: 0 0 58.33333% !important;
            max-width: 58.33333% !important;
        }
        .col-lg-8 {
            flex: 0 0 66.66667% !important;
            max-width: 66.66667% !important;
        }
        .col-lg-9 {
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }
        .col-lg-10 {
            flex: 0 0 83.33333% !important;
            max-width: 83.33333% !important;
        }
        .col-lg-11 {
            flex: 0 0 91.66667% !important;
            max-width: 91.66667% !important;
        }
        .col-lg-12 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .d-lg-block {
            display: block !important;
        }
        .d-lg-flex {
            display: flex !important;
        }
    }
}
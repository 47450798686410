@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&family=Pontano+Sans&display=swap');

@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/css/all.css';

@import "./font_austin";

@import
    "front/layout/variables",
    "front/layout/base",
    "front/layout/buscador",
    "front/layout/error-page",
    "front/layout/footer",
    "front/layout/header",
    "front/layout/menu",
    "front/layout/noticias",
    "front/layout/podcast",
    "front/layout/portada",
    "front/layout/new_specials.css"
;

@import
    "front/components/buttons",
    "front/components/helpers",
    "front/components/links",
    "front/components/paginator",
    "front/components/publicidad",
    "front/components/social",
    "front/components/spinner",
    "front/components/text",
    "front/components/jwplayer",
    "front/components/form",
    "front/components/listados",
    "front/components/print",
    "front/components/slick",
    "front/components/galeria"
;

a.social {
    height: 40px;
    width: 40px;
    -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;

    &:hover {
        border: none;
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
    }
}

a.feedly {
    display: inline-block;
    font-weight: 600;
}
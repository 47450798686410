$font-family-body: 'Raleway', sans-serif !default;
$font-family-title: 'Playfair Display', serif;
$font-family-title-block: 'Oswald', sans-serif;

$text-size-base: 1rem !default;  // 16px
$text-size-12: 0.75rem !default;  // 12px
$text-size-13: 0.813rem !default;  // 13px
$text-size-14: 0.875rem !default;  // 14px
$text-size-18: 1.125rem !default;  // 18px
$text-size-19: 1.188rem !default;  // 19px
$text-size-20: 1.25rem !default;  // 20px
$text-size-22: 1.375rem !default;  // 22px
$text-size-24: 1.5rem !default;  // 24px
$text-size-25: 1.563rem !default;  // 25px
$text-size-30: 1.875rem !default;  // 30px
$text-size-36: 2.25rem !default;  // 36px
$text-size-40: 2.5rem !default;  // 40px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$body-bg: #fff !default;

$color-body: #5b5b5b !default;
$color-body-inline: #dee2e6 !default;
$color-body-no-publicado: #5bc6f830 !default;
$color-white: #fff !default;
$color-dark: #000 !default;
$color-light: #999 !default;
$color-grey: #f4f4f4 !default;

$transition-link: all 0.2s ease !default;

h1, .h1 {
    font-size: $text-size-36;
}

b, strong {
    font-weight: 600 !important;
}

.text-12 {
    font-size: $text-size-12 !important;
}

.text-14 {
    font-size: $text-size-14 !important;
}

.text-18 {
    font-size: $text-size-18 !important;
}

.text-base {
    font-size: $text-size-base !important;
}

.text-20 {
    font-size: $text-size-20 !important;
}

.text-22 {
    font-size: $text-size-22 !important;
}

.text-24 {
    font-size: $text-size-24 !important;
}

.text-25 {
    font-size: $text-size-25 !important;
}

.text-30 {
    font-size: $text-size-30 !important;
}

.text-36 {
    font-size: $text-size-36 !important;
}

.text-40 {
    font-size: $text-size-40 !important;
}

.text-muted {
    color: $color-light !important;
}

.text-light {
    color: $color-light !important;
}

.text-white {
    color: $color-white !important;
}

.text-dark {
    color: $color-dark !important;
}

.text-font-primary,
.title {
    font-family: $font-family-title;
}

.title {
    color: $color-dark;
    
    &.block {
        font-family: $font-family-title-block;
        font-size: 20px;
    }
}

.text-font-secondary {
    font-family: $font-family-title-block;
}

.text-font-brandpublisher {
    font-family: 'Pontano Sans', sans-serif;
}

@media (max-width: 575px) {
    .text-22-sm {
        font-size: $text-size-22 !important;
    }
}

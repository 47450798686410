.error-page {
    max-width: 440px;
    margin: 60px auto 140px;
    p {
        max-width: 390px;
        margin: 0 auto; 
    }
    a {
        color: inherit;
        border-bottom: 1px solid rgba(102,204,255,.5);
        text-decoration: none;
        
        &:hover {
            color: inherit;
            border-bottom: 1px solid rgba(102,204,255,1);
            text-decoration: none;
        }
    }
    hr {
        width: 290px;
    }
    .buscador {
        .buscador-form {
            position: relative;
            width: 320px;
            .busqueda-input {
                outline: none;
                width: 100%;
                border: 1px solid $color-light;
                padding: 5px 20px;
            }
            .busqueda-submit {
                text-indent: -99px;
                background: transparent;
                width: 25px;
                outline: none;
                border: none;
                padding: 0;
                position: absolute;
                right: 25px;
                z-index: 2;
            }
            i {
                position: absolute;
                right: 30px;
                z-index: 1;
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 400px) {
    .error-page {
        .buscador {
            .buscador-form {
                position: relative;
                width: 100%;
            }
        }
    }
}
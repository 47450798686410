.page-listado-actualidad, .page-listado-opinion {
    .title {
        font-size: 18px!important;
    }
}

.page-listado, .page-listado-reportaje {
    .title {
        font-size: 20px!important;
    }
}


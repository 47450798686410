@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
    form {
        display: none;
    }
    #header {
        margin-bottom: 30px;
    }
    a, a:visited {
        text-decoration: none !important;
    }
    .col-absolute-especial-lateral {
        position: relative;
        margin-top: 0;
    }
    .col-height-especial-lateral {
        height: auto;
    }
    .hr-vertical {
        border-right: none;
        margin: 0 auto;
    }
    .noticia .cuerpo blockquote ::before {
        display: none;
    }
    .noticia .cuerpo blockquote {
        border: none;
    }
    .publi-noticia-FOOTERSTICKYINTERIOR.visible {
        display: none;
    }
    .publi-noticia-FOOTERSTICKYINTERIOR {
        display: none;
    }
}
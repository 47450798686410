#footer {
    background: #efefef;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-family: $font-family-title-block;
    padding-top: 50px;

    a {
        border: none;
    }

    .btn-footer {
        border: 1px solid #ccc;
        min-width: 220px;
        max-width: 255px;
    }

    hr {
        margin: 30px 0 !important;
    }
}

#logo_footer {
    width: 148px;
    height: 68px;
}
a {
    color: #000;
    transition: $transition-link;

    &:hover {
        border-bottom: 1px solid rgba(0,0,0,.15);
        color: inherit;
        text-decoration: none;
        transition: $transition-link;
    }
}

.tags {
    font-size: $text-size-12;
}
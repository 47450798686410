/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      display: none;
  }

  display: none;

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
	display: flex;
	justify-content: center;
	margin: 0;
  padding: 0 !important;
	
	list-style-type: none;

  position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0;
    bottom: 30px;
	
		li {
			margin: 0 0.25rem;
		}
	
		button {
			display: block;
			width: 10px;
			height: 10px;
			padding: 0;
			
			border: none;
			border-radius: 100%;
			background-color: #fff;
      opacity: 1;
			
			text-indent: -9999px;

      &:hover {
        opacity: 0.75;
      }
      &:focus {
        outline: none;
      }
		}
	
		li.slick-active button {
			background-color: #fff;
      opacity: 0.75;
		}
	
}

.slick-prev,
.slick-next {
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 99;
  color: #fff;
  opacity: 0.25;
  font-size: 30px;

  &:hover, &:focus {
    background: transparent;
    outline: none;
  }

  &:focus {
      opacity: 0.25;
  }

  &:hover {
      opacity: 1;
  }

  &.slick-disabled {
    opacity: 0.25;
  }
}

.slick-prev {
  left: 20px;

  [dir="rtl"] & {
    left: auto;
    right: 20px;
  }

}

.slick-next {
  right: 20px;

  [dir="rtl"] & {
    left: 20px;
    right: auto;
  }
}

.galeria {
  .slick-slider {
    .slick-track {
      .pie-foto {
        text-align: right;
        font-size: 14px;
        min-height: 21px;
      }
    }
    .slick-dots {
      left: 0;
      bottom: 22px;
      transform: none;
      button {
        background-color: #000;
        opacity: 0.5;
      }
      .slick-active {
        button {
          opacity: 1;
        }
      }
    }
  }
  .slick-prev, .slick-next {
    opacity: unset;
    &::after {
      display: block;
      content: "";
      width: 35px;
      height: 35px;
      background: #fff;
      opacity: 0.5;
      position: absolute;
      top: -3px;
      left: -8px;
      border-radius: 100%;
    }
    &::before {
      opacity: 1;
    }
  }
  .slick-next {
    &::after {
      left: -11px;
    }
  }
}

.galeria {
    margin-top: 30px;
    max-width: 633px;
    width: 100%;
    
    .slick-slider {
      .slick-track {
        .pie-foto {
          text-align: right;
          font-size: 14px;
        }
      }
      .slick-dots {
        left: 0;
        top: -10px;
        transform: none;
        button {
          background-color: #000;
          opacity: 0.5;
        }
        .slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
    .slick-prev, .slick-next {
      opacity: unset;
      &::after {
        display: block;
        content: "";
        width: 35px;
        height: 35px;
        background: #fff;
        opacity: 0.5;
        position: absolute;
        top: -3px;
        left: -8px;
        border-radius: 100%;
      }
      &::before {
        opacity: 1;
      }
    }
    .slick-next {
      &::after {
        left: -11px;
      }
    }
}

@media (max-width: 1430px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 625px;
                }
            }
        }
    }
}

@media (max-width: 1425px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 620px;
                }
            }
        }
    }
}

@media (max-width: 1420px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 615px;
                }
            }
        }
    }
}

@media (max-width: 1415px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 610px;
                }
            }
        }
    }
}

@media (max-width: 1410px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 605px;
                }
            }
        }
    }
}

@media (max-width: 1405px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 600px;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 595px;
                }
            }
        }
    }
}

@media (max-width: 1395px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 590px;
                }
            }
        }
    }
}

@media (max-width: 1390px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 585px;
                }
            }
        }
    }
}

@media (max-width: 1385px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 580px;
                }
            }
        }
    }
}

@media (max-width: 1380px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 575px;
                }
            }
        }
    }
}

@media (max-width: 1375px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 570px;
                }
            }
        }
    }
}

@media (max-width: 1370px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 565px;
                }
            }
        }
    }
}

@media (max-width: 1365px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 560px;
                }
            }
        }
    }
}

@media (max-width: 1360px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 555px;
                }
            }
        }
    }
}

@media (max-width: 1359px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 554px;
                }
            }
        }
    }
}

@media (max-width: 1358px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 553px;
                }
            }
        }
    }
}

@media (max-width: 1357px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 552px;
                }
            }
        }
    }
}

@media (max-width: 1356px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 551px;
                }
            }
        }
    }
}

@media (max-width: 1355px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 550px;
                }
            }
        }
    }
}

@media (max-width: 1350px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 545px;
                }
            }
        }
    }
}

@media (max-width: 1345px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 540px;
                }
            }
        }
    }
}

@media (max-width: 1340px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 535px;
                }
            }
        }
    }
}

@media (max-width: 1335px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 530px;
                }
            }
        }
    }
}

@media (max-width: 1330px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 525px;
                }
            }
        }
    }
}

@media (max-width: 1325px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 520px;
                }
            }
        }
    }
}

@media (max-width: 1320px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 515px;
                }
            }
        }
    }
}

@media (max-width: 1315px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 510px;
                }
            }
        }
    }
}

@media (max-width: 1310px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 505px;
                }
            }
        }
    }
}

@media (max-width: 1305px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 500px;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 495px;
                }
            }
        }
    }
}

@media (max-width: 1295px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 490px;
                }
            }
        }
    }
}

@media (max-width: 1290px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 485px;
                }
            }
        }
    }
}

@media (max-width: 1285px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 480px;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 475px;
                }
            }
        }
    }
}

@media (max-width: 1275px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 470px;
                }
            }
        }
    }
}

@media (max-width: 1270px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 465px;
                }
            }
        }
    }
}

@media (max-width: 1265px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 460px;
                }
            }
        }
    }
}

@media (max-width: 1260px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 785px;
                }
            }
        }
    }
}

@media (max-width: 1259px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 784px;
                }
            }
        }
    }
}

@media (max-width: 1258px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 783px;
                }
            }
        }
    }
}

@media (max-width: 1257px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 782px;
                }
            }
        }
    }
}

@media (max-width: 1256px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 781px;
                }
            }
        }
    }
}

@media (max-width: 1255px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 780px;
                }
            }
        }
    }
}

@media (max-width: 1250px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 775px;
                }
            }
        }
    }
}

@media (max-width: 1245px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 770px;
                }
            }
        }
    }
}

@media (max-width: 1240px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 765px;
                }
            }
        }
    }
}

@media (max-width: 1235px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 760px;
                }
            }
        }
    }
}

@media (max-width: 1230px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 755px;
                }
            }
        }
    }
}

@media (max-width: 1225px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 750px;
                }
            }
        }
    }
}

@media (max-width: 1220px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 745px;
                }
            }
        }
    }
}

@media (max-width: 1215px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 740px;
                }
            }
        }
    }
}

@media (max-width: 1210px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 735px;
                }
            }
        }
    }
}

@media (max-width: 1205px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 730px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 725px;
                }
            }
        }
    }
}

@media (max-width: 1195px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 720px;
                }
            }
        }
    }
}

@media (max-width: 1190px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 715px;
                }
            }
        }
    }
}

@media (max-width: 1185px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 710px;
                }
            }
        }
    }
}

@media (max-width: 1180px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 705px;
                }
            }
        }
    }
}

@media (max-width: 1175px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 700px;
                }
            }
        }
    }
}

@media (max-width: 1170px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 695px;
                }
            }
        }
    }
}

@media (max-width: 1165px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 690px;
                }
            }
        }
    }
}

@media (max-width: 1160px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 685px;
                }
            }
        }
    }
}

@media (max-width: 1155px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 680px;
                }
            }
        }
    }
}

@media (max-width: 1150px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 675px;
                }
            }
        }
    }
}

@media (max-width: 1145px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 670px;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 665px;
                }
            }
        }
    }
}

@media (max-width: 1135px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 660px;
                }
            }
        }
    }
}

@media (max-width: 1130px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 655px;
                }
            }
        }
    }
}

@media (max-width: 1125px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 650px;
                }
            }
        }
    }
}

@media (max-width: 1120px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 645px;
                }
            }
        }
    }
}

@media (max-width: 1115px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 640px;
                }
            }
        }
    }
}

@media (max-width: 1110px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 635px;
                }
            }
        }
    }
}

@media (max-width: 1105px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 630px;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 625px;
                }
            }
        }
    }
}

@media (max-width: 1095px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 620px;
                }
            }
        }
    }
}

@media (max-width: 1090px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 615px;
                }
            }
        }
    }
}

@media (max-width: 1085px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 610px;
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 605px;
                }
            }
        }
    }
}

@media (max-width: 1075px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 600px;
                }
            }
        }
    }
}

@media (max-width: 1070px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 595px;
                }
            }
        }
    }
}

@media (max-width: 1065px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 590px;
                }
            }
        }
    }
}

@media (max-width: 1060px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 585px;
                }
            }
        }
    }
}

@media (max-width: 1055px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 580px;
                }
            }
        }
    }
}

@media (max-width: 1050px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 575px;
                }
            }
        }
    }
}

@media (max-width: 1045px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 570px;
                }
            }
        }
    }
}

@media (max-width: 1040px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 565px;
                }
            }
        }
    }
}

@media (max-width: 1035px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 560px;
                }
            }
        }
    }
}

@media (max-width: 1030px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 555px;
                }
            }
        }
    }
}

@media (max-width: 1025px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 550px;
                }
            }
        }
    }
}

@media (max-width: 1020px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 545px;
                }
            }
        }
    }
}

@media (max-width: 1015px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 540px;
                }
            }
        }
    }
}

@media (max-width: 1010px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 535px;
                }
            }
        }
    }
}

@media (max-width: 1005px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 530px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 525px;
                }
            }
        }
    }
}

@media (max-width: 995px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 520px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 716px;
                }
            }
        }
    }
}

@media (max-width: 990px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 715px;
                }
            }
        }
    }
}

@media (max-width: 985px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 710px;
                }
            }
        }
    }
}

@media (max-width: 980px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 705px;
                }
            }
        }
    }
}

@media (max-width: 975px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 700px;
                }
            }
        }
    }
}

@media (max-width: 970px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 695px;
                }
            }
        }
    }
}

@media (max-width: 965px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 690px;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 685px;
                }
            }
        }
    }
}

@media (max-width: 955px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 680px;
                }
            }
        }
    }
}

@media (max-width: 950px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 675px;
                }
            }
        }
    }
}

@media (max-width: 945px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 670px;
                }
            }
        }
    }
}

@media (max-width: 940px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 665px;
                }
            }
        }
    }
}

@media (max-width: 935px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 660px;
                }
            }
        }
    }
}

@media (max-width: 930px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 655px;
                }
            }
        }
    }
}

@media (max-width: 925px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 650px;
                }
            }
        }
    }
}

@media (max-width: 920px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 645px;
                }
            }
        }
    }
}

@media (max-width: 915px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 640px;
                }
            }
        }
    }
}

@media (max-width: 910px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 635px;
                }
            }
        }
    }
}

@media (max-width: 905px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 630px;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 625px;
                }
            }
        }
    }
}

@media (max-width: 895px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 620px;
                }
            }
        }
    }
}

@media (max-width: 890px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 615px;
                }
            }
        }
    }
}

@media (max-width: 885px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 610px;
                }
            }
        }
    }
}

@media (max-width: 880px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 605px;
                }
            }
        }
    }
}

@media (max-width: 875px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 600px;
                }
            }
        }
    }
}

@media (max-width: 870px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 595px;
                }
            }
        }
    }
}

@media (max-width: 865px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 590px;
                }
            }
        }
    }
}

@media (max-width: 860px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 585px;
                }
            }
        }
    }
}

@media (max-width: 855px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 580px;
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 575px;
                }
            }
        }
    }
}

@media (max-width: 845px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 570px;
                }
            }
        }
    }
}

@media (max-width: 840px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 565px;
                }
            }
        }
    }
}

@media (max-width: 835px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 560px;
                }
            }
        }
    }
}

@media (max-width: 830px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 555px;
                }
            }
        }
    }
}

@media (max-width: 825px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 550px;
                }
            }
        }
    }
}

@media (max-width: 820px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 545px;
                }
            }
        }
    }
}

@media (max-width: 815px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 540px;
                }
            }
        }
    }
}

@media (max-width: 810px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 535px;
                }
            }
        }
    }
}

@media (max-width: 805px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 530px;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 525px;
                }
            }
        }
    }
}

@media (max-width: 795px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 520px;
                }
            }
        }
    }
}

@media (max-width: 790px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 515px;
                }
            }
        }
    }
}

@media (max-width: 785px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 510px;
                }
            }
        }
    }
}

@media (max-width: 780px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 505px;
                }
            }
        }
    }
}

@media (max-width: 775px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 500px;
                }
            }
        }
    }
}

@media (max-width: 770px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 495px;
                }
            }
        }
    }
}

@media (max-width: 765px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 490px;
                }
            }
        }
    }
}

@media (max-width: 760px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 485px;
                }
            }
        }
    }
}

@media (max-width: 755px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 480px;
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 475px;
                }
            }
        }
    }
}

@media (max-width: 745px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 470px;
                }
            }
        }
    }
}

@media (max-width: 740px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 465px;
                }
            }
        }
    }
}

@media (max-width: 735px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 460px;
                }
            }
        }
    }
}

@media (max-width: 730px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 455px;
                }
            }
        }
    }
}

@media (max-width: 725px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 450px;
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 445px;
                }
            }
        }
    }
}

@media (max-width: 715px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 440px;
                }
            }
        }
    }
}

@media (max-width: 710px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 435px;
                }
            }
        }
    }
}

@media (max-width: 705px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 430px;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 425px;
                }
            }
        }
    }
}

@media (max-width: 695px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 420px;
                }
            }
        }
    }
}

@media (max-width: 690px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 415px;
                }
            }
        }
    }
}

@media (max-width: 685px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 410px;
                }
            }
        }
    }
}

@media (max-width: 680px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 405px;
                }
            }
        }
    }
}

@media (max-width: 675px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 400px;
                }
            }
        }
    }
}

@media (max-width: 670px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 395px;
                }
            }
        }
    }
}

@media (max-width: 665px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 390px;
                }
            }
        }
    }
}

@media (max-width: 660px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 385px;
                }
            }
        }
    }
}

@media (max-width: 655px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 380px;
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 375px;
                }
            }
        }
    }
}

@media (max-width: 645px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 370px;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 365px;
                }
            }
        }
    }
}

@media (max-width: 635px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 360px;
                }
            }
        }
    }
}

@media (max-width: 630px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 355px;
                }
            }
        }
    }
}

@media (max-width: 625px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 350px;
                }
            }
        }
    }
}

@media (max-width: 620px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 345px;
                }
            }
        }
    }
}

@media (max-width: 615px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 340px;
                }
            }
        }
    }
}

@media (max-width: 610px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 335px;
                }
            }
        }
    }
}

@media (max-width: 605px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 330px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 325px;
                }
            }
        }
    }
}

@media (max-width: 595px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 320px;
                }
            }
        }
    }
}

@media (max-width: 590px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 315px;
                }
            }
        }
    }
}

@media (max-width: 585px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 310px;
                }
            }
        }
    }
}

@media (max-width: 580px) {
    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 305px;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .galeria {
        max-width: 400px;
    }

    .container-full-noticia{
        .container-con-rascas{
            article.noticia {
                .galeria {
                    width: 100%;
                }
            }
        }
    }
}
.roba-paginas-nodo {
    flex: 1;

    .publi-noticia-ROBA1 {
        margin-bottom: 20px;
    }

    .publi-noticia-ROBA2 {
        margin-bottom: 20px;
    }

    .publi-noticia-ROBA2STICKY, .publi-noticia-ROBA2STICKYCONTEXTUAL {
        margin-bottom: 20px;
        position: sticky!important;
        top: 70px;
    }
}

.portada {
    #main {
        > .container {
            position: relative;
            .publi-noticia-RASCAIZQUIERDA {
                position: absolute;
                top: 0;
                left: -200px;
                max-width: 200px;
            }
            .publi-noticia-RASCADERECHA {
                position: absolute;
                top: 0;
                right: -200px;
                max-width: 200px;
            }
            .publi-noticia-RASCAIZQUIERDASTICKY {
                position: absolute;
                top: 0;
                left: -200px;
                height: 100%;
                max-width: 200px;
                a {
                    display: block;
                    position: sticky!important;
                    top: 70px;
                }
            }
            .publi-noticia-RASCADERECHASTICKY {
                position: absolute;
                top: 0;
                right: -200px;
                height: 100%;
                max-width: 200px;
                a {
                    display: block;
                    position: sticky!important;
                    top: 70px;
                }
            }
        }
    }
}

body.noticia-fondo {
    .publi-noticia-LARGUEROINTERIOR {
        display: none;
    }
}

// .container.noticia {
//     position: relative;
//     .publi-noticia-RASCAIZQUIERDAINTERIOR {
//         position: absolute;
//         top: 0;
//         left: -200px;
//         max-width: 200px;
//     }
//     .publi-noticia-RASCADERECHAINTERIOR {
//         position: absolute;
//         top: 0;
//         right: -200px;
//         max-width: 200px;
//     }
//     .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY {
//         position: absolute;
//         top: 0;
//         left: -200px;
//         height: 100%;
//         max-width: 200px;
//         a {
//             margin-bottom: 20px;
//             display: block;
//             position: sticky!important;
//             top: 70px;
//         }
//     }
//     .publi-noticia-RASCADERECHAINTERIORSTICKY {
//         position: absolute;
//         top: 0;
//         right: -200px;
//         height: 100%;
//         max-width: 200px;
//         a {
//             margin-bottom: 20px;
//             display: block;
//             position: sticky!important;
//             top: 70px;
//         }
//     }
// }
/* .container.especial {
    position: relative;
    .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY {
        position: absolute;
        top: 0;
        left: -200px;
        height: 100%;
        max-width: 200px;
        a {
            margin-bottom: 20px;
            display: block;
            position: sticky!important;
            top: 70px;
        }
    }
    .publi-noticia-RASCADERECHAINTERIORSTICKY {
        position: absolute;
        top: 0;
        right: -200px;
        height: 100%;
        max-width: 200px;
        a {
            margin-bottom: 20px;
            display: block;
            position: sticky!important;
            top: 70px;
        }
    }
} */

.header-especial-background, .header-especial-small, .header-especial-big {
    height: auto !important;
}
.main-especial-background {
    background: url("../../../images/tangity.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;  
}
//.container-full-especial-small {
//    background: white;
//    width: 100%;
//    .main-especial-small {
//        background: white;
//        display: flex;
//        padding-right: 60px;
//        padding-left: 60px;
//        margin: 0 auto;
//        .info-izq {
//            width: 200px !important;
//            height: auto;
//            flex-basis: 200px;
//            flex-grow: 0;
//            flex-shrink: 0;
//            background: white;
//            img {
//                position: -webkit-sticky;
//                position: sticky;
//                top: 100px;
//            }
//        }
//        .info-izq-rw {
//            width: 200px !important;
//            height: auto;
//            flex-basis: 400px;
//            flex-grow: 0;
//            flex-shrink: 0;
//            background: white;
//            padding-top: 50px;
//            div {
//                position: -webkit-sticky;
//                position: sticky;
//                top: 100px;
//            }
//        }
//        .container.especial-small {
//            max-width: 1030px !important;
//            padding-right: 30px;
//            padding-left: 30px;
//        }
//        .info-der {
//            width: 200px !important;
//            height: auto;
//            flex-basis: 200px;
//            flex-grow: 0;
//            flex-shrink: 0;
//            background: white;
//            img {
//                position: -webkit-sticky;
//                position: sticky;
//                top: 100px;
//            }
//        }
//    }
//}

.container-full-especial-small {
    background: white;
    width: calc(100vw - 110px); /* Para pantallas grandes, restamos 110px (55px a cada lado) */
    margin: 55px auto;

    /* Ajustes para pantallas pequeñas */
    @media (max-width: 768px) {
        width: 100vw; /* Ocupa todo el ancho de la pantalla en móviles */
        margin: 0; /* Eliminar márgenes en móviles */
    }

    .main-especial-small {
        background: white;
        display: flex;
        padding: 0;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;

        /* Ajustes para pantallas pequeñas */
        @media (max-width: 768px) {
            flex-direction: column; /* Alineamos los elementos en columna para que se adapten mejor en móviles */
            padding: 0 15px; /* Añadir un poco de padding interno */
        }

        /* Bloque lateral izquierdo */
        .info-izq {
            width: 200px !important;
            flex-shrink: 0;
            background: white;
            margin-right: 55px; /* Margen derecho para pantallas grandes */

            @media (max-width: 768px) {
                width: 100%; /* En móviles, ocupamos todo el ancho */
                margin: 0; /* Eliminamos márgenes en móviles */
            }

            img {
                position: -webkit-sticky;
                position: sticky;
                top: 100px;
            }
        }

        /* Contenido central */
        .container.especial-small {
            max-width: 100%;
            padding-right: 30px;
            padding-left: 30px;
            flex-grow: 1;

            @media (max-width: 768px) {
                padding-right: 15px;
                padding-left: 15px; /* Reducir el padding en móviles */
            }
        }

        /* Bloque lateral derecho */
        .info-der {
            width: 200px !important;
            flex-shrink: 0;
            background: white;
            margin-left: 55px; /* Margen izquierdo para pantallas grandes */

            @media (max-width: 768px) {
                width: 100%; /* En móviles, ocupamos todo el ancho */
                margin: 0; /* Eliminamos márgenes en móviles */
            }

            img {
                position: -webkit-sticky;
                position: sticky;
                top: 100px;
            }
        }
    }
}

//texto auxiliar

.text-aux-rw {
    display: block; /* Ocultar por defecto */
}

@media (min-width: 1250px) {
    .text-aux-rw {
        display: none;
    }
}

.title-new-rw {
    font-size: 38px;
    font-weight: bold;
    line-height: 1.2;
    font-style: italic;
}

@media (min-width: 1810px) {
    .title-new-rw {
        font-size: 50px;
    }
}

@media (min-width: 2106px) {
    .title-new-rw {
        font-size: 70px;
    }
}
//END


.container-full-especial-big {
    background: white;
    width: 100%;
    .main-especial-big {
        background: white;
        display: flex;
        max-width: 1830px !important;
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
        .info-izq {
            max-width: 400px !important;
            height: auto;
            flex-basis: 400px;
            flex-grow: 0;
            flex-shrink: 0;
            padding-right: 0 !important;
            padding-left: 0 !important;
            background: white;
            img {
                position: -webkit-sticky;
                position: sticky;
                top: 100px;
            }
        }
        .info-izq-rw {
            width: 200px !important;
            height: auto;
            flex-basis: 400px;
            flex-grow: 0;
            flex-shrink: 0;
            background: white;
            padding-top: 50px;
            div {
                position: -webkit-sticky;
                position: sticky;
                top: 100px;
            }
        }
        .container.especial-big {
            max-width: 1030px !important;
            padding-right: 30px;
            padding-left: 30px;
        }
        .info-der {
            max-width: 400px !important;
            height: auto;
            flex-basis: 400px;
            flex-grow: 0;
            flex-shrink: 0;
            background: white;
            img {
                position: -webkit-sticky;
                position: sticky;
                top: 100px;
            }
        }
    }
}

.especial-background, .especial-small, .especial-big {
    padding-top: 48px;
}
.container.especial {
    position: relative;
    background: $color-white;
}

.publi-noticia-BREAKSCROLLINTERIOR {
    display: none;
}

.publi-noticia-gif-hori-full, .publi-noticia-gif-hori-content, .publi-noticia-BREAKSCROLL {
    img {
        width: 100%;
        margin: 0 auto;
        display: block;
    }
}

.publi-noticia-gif-hori-content {
    margin: 35px auto;
    padding: 0 15px;
}

.publicidad-faldon-portada, .publicidad-faldon-interior , .publi-noticia-FALDONPORTADA  , .publi-noticia-FALDONINTERIOR, .publi-noticia-FALDONINTERIORCONTEXTUAL {
    display: none;
    position: fixed;
    z-index: 999999;
    background: #000;
    bottom: 0;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
    }
    &.etiqueta{
        z-index: 9999999;
    }
}

#header {
    .publi-noticia-LARGUERO {
        display: none;
    }
    .publi-noticia-LARGUEROSTICKY {
        display: none;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10006;
    }
}

.publi-noticia-FOOTER {
    display: none;
}

.publi-noticia-FOOTERSTICKY {
    display: none;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10006;
}

.publi-noticia-FOOTERSTICKYINTERIOR, .publi-noticia-FOOTERSTICKYINTERIORCONTEXTUAL {
    display: block;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10006;
}

.publi-noticia-FOOTERSTICKYINTERIORCONTEXTUAL {
    display: block;
    &.oculto{
        display: none;
    }
}

.publi-noticia-FOOTERSTICKYINTERIOR {
    display: none;
    &.visible{
        display: block;
    }
}

.portada {
    #header {
        .publi-noticia-LARGUERO {
            display: block;
        }
        .publi-noticia-LARGUEROSTICKY {
            display: block;
        }
        .publi-noticia-LARGUEROINTERIOR {
            display: none;
        }
    }
    .publi-noticia-FOOTER {
        display: block;
    }
    .publi-noticia-FOOTERSTICKY {
        display: block;
    }
}

.publi-noticia-PATROCINIO {
    margin-bottom: 24px;
}

.publi-noticia-MENUPRINCIPAL + .social {
    display: none;
}

@media (max-width: 1250px) {
    .container-full-especial-small {
        .main-especial-small {
            .info-izq {
                display: none;
            }
            .info-izq-rw {
                display: none;
            }

        }
    }
}

@media (max-width: 1391px) {
    .container-full-especial-big {
        .main-especial-big {
            .info-izq {
                display: none;
            }
            .info-izq-rw {
                display: none;
            }
        }
    }
}

@media (max-width: 860px) {
    .publi-noticia-MENUPRINCIPAL {
        display: none;
    }
}

@media (max-width: 575px) {
    .publi-noticia-gif-hori-full {
        display: none;
    }
    .publicidad-faldon-interior, .publi-noticia-FALDONINTERIOR, .publi-noticia-FALDONINTERIORCONTEXTUAL {
        display: flex;
    }
    .publi-noticia-FALDONINTERIOR {
        display: none;
        &.visible{
            display: flex;
        }
    }
    .publi-noticia-FALDONINTERIORCONTEXTUAL {
        display: flex;
        &.oculto{
            display: none;
        }
    }
    .portada {
        .publicidad-faldon-portada, .publi-noticia-FALDONPORTADA {
            display: flex;
        }
        .publicidad-faldon-interior, .publi-noticia-FALDONINTERIOR, .publi-noticia-FALDONINTERIORCONTEXTUAL {
            display: none;
        }
        .publi-noticia-FOOTER {
            display: none;
        }
        .publi-noticia-FOOTERSTICKY {
            display: none;
        }
    }

    .publi-noticia-FOOTERSTICKYINTERIOR {
        display: none !important;
    }

    .publi-noticia-BREAKSCROLL {
        display: none;
    }
    .publi-noticia-BREAKSCROLLINTERIOR {
        display: block;
    }

    .publi-noticia-PATROCINIO {
        display: none;
    }

    .portada {
        #header {
            .publi-noticia-LARGUERO {
                display: none;
            }
            .publi-noticia-LARGUEROSTICKY {
                display: none;
            }
        }
    }
    .publi-noticia-LARGUEROINTERIOR {
        display: none;
    }

    // .container.noticia {
    //     .publi-noticia-RASCAIZQUIERDAINTERIOR, .publi-noticia-RASCADERECHAINTERIOR, .publi-noticia-RASCAIZQUIERDAINTERIORSTICKY, .publi-noticia-RASCADERECHAINTERIORSTICKY {
    //         display: none;
    //     }
    // }

    .portada {
        #main {
            > .container {
                .publi-noticia-RASCAIZQUIERDA, .publi-noticia-RASCADERECHA, .publi-noticia-RASCAIZQUIERDASTICKY, .publi-noticia-RASCADERECHASTICKY {
                    display: none;
                }
            }
        }
    }

    .roba-paginas-nodo {
        .publi-noticia-ROBA1{
            display: none;
        }
        .publi-noticia-ROBA2{
            display: none;
        }
        .publi-noticia-ROBA2STICKY, .publi-noticia-ROBA2STICKYCONTEXTUAL {
            display: none;
        }
    }
    #bloque-noticias-p1 {
        display: none;
    }
    .container-full-especial-small {
        .main-especial-small {
            .info-der {
                display: none;
            }
        }
    }
    .container-full-especial-big {
        .main-especial-big {
            .info-der {
                display: none;
            }
        }
    }
}

.publi-noticia-LARGUEROINTERIOR {
    display: none;
}

body.noticia {
    .publi-noticia-LARGUEROINTERIOR.visible {
        display: block;
    }
    .publi-noticia-LARGUEROINTERIOR.oculto {
        display: none;
    }
}

@media (max-width: 575px) {
    body.noticia {
        .publi-noticia-LARGUEROINTERIOR {
            display: none;
        }
    }
}
.hr-vertical {
    border-right: 1px solid rgba(0,0,0,.1);
}

.hr-strong {
    border-top: 2px solid #000;
}

.hr-scroll {
    border-top: 4px solid #d3d3d3;
    margin: 0;
    z-index: 1;
    position: relative;
}

.p-relative {
    position: relative;
}

.sticky-portada {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 80px;
}

.col-absolute-especial-lateral {
    position: relative;
    margin-top: 0;
}

.no-padding {
    padding: 0;
}

@media (max-width: 991px) {
    .w-lg-350px {
        max-width: 350px;
    }
    .mt-lg-30px {
        margin-top: 30px;
    }
    .col-absolute-especial-lateral {
        position: absolute;
        margin-top: 395px;
    }
    .col-height-especial-lateral {
        height: 822px;
    }
    .mb-lg-30px {
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .w-md-350px {
        max-width: 350px;
    }
    .mt-md-30px {
        margin-top: 30px;
    }
    .col-absolute-especial-lateral {
        position: relative;
        margin-top: 30px;
    }
    .col-height-especial-lateral {
        height: auto;
    }
}

@media (max-width: 575px) {
    .no-padding-sm {
        padding: 0;
    }
}
#menu {
    align-items: center;
    color: #000;
    display: flex;
    position: relative;

    > ul {
        margin-bottom: 0;
        z-index: 1;
        li {
            margin-left: 3em;

            a:hover {
                border-bottom: none;
                color: #5bc6f8;
                text-decoration: none;
            }
        }
    }

    label:hover {
        color: #5bc6f8;
    }
}

#menu-toggle {
    background-color: #fff;
    color: #000;
    left: 0;
    height: auto;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-backface-visibility: hidden;
    z-index: 100000;

    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    &.is-visible {
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);

        -webkit-box-shadow: 0 15px 10px -10px rgba(0,0,0,.5);
        -moz-box-shadow: 0 15px 10px -10px rgba(0,0,0,.5);
        box-shadow: 0 15px 10px -10px rgba(0,0,0,.5);
    }

    h3,
    h5 {
        font-size: $text-size-base;
    }

    h3 {
        color: #929292;
    }

    a {
        color: #000;
    }

    .enlaces-menu {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 10px;
        .secciones {
            flex: 1 0 calc(15% - 20px);
            max-width: calc(15% - 20px);
            margin-left: 20px;
            li {
                margin: 0 0 0.5em;
    
                a {
                    font-size: 18px !important;
                    font-weight: 700;
    
                    &:hover {
                        border: 0;
                        color: #5bc6f8;
                    }
                }
            }
        }
    
        .categorias {
            flex: 1 0 calc(45% - 20px);
            max-width: calc(45% - 20px);
            margin-left: 20px;
            .temas-momento {
                display: flex;
                flex-wrap: wrap;
                max-height: 170px;
                flex-direction: column;
                h5 {
                    margin-bottom: 10px;
                }
            }
        }

        .noticias-menu {
            flex: 1 0 calc(40% - 20px);
            max-width: calc(40% - 20px);
            margin-left: 20px;
            .noticia-menu-container {
                display: flex;
                flex-wrap: wrap;
                margin-left: -20px;
                .noticia-menu {
                    flex: 1 0 calc(50% - 20px);
                    max-width: calc(50% - 20px);
                    margin-left: 20px !important;
                    figure {
                        margin: 0;
                    }
                    .fecha-autor {
                        margin-top: 8px !important;
                    }
                    .title {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }

    .separador {
        background-color: #5bc6f8;
        height: 3px;
        width: 50px;
        margin-bottom: 1em;
    }

    .social {
        background-color: #f0f0f0;
        .social-content {
            display: flex;
            vertical-align: center;
            .rrss {
                display: flex;
                margin-right: 40px;
                align-items: center;
            }
            .enlace-header {
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1;
                background: $color-white;
                border: 1px solid $color-light;
                border-radius: 10px;
                font-size: 14px;
                width: 320px;
                text-align: center;
                height: 70px;
                line-height: 70px;
                margin-right: 40px;
                @include transition();
                &:hover {
                    background: #fafafa;
                }
            }
            .enlaces-content {
                display: flex;
                flex-wrap: wrap;
                .enlaces-column {
                    display: flex;
                    flex-direction: column;
                    .enlace-header-small {
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 28px;
                        background: $color-white;
                        border: 1px solid $color-light;
                        border-radius: 6px;
                        font-size: 14px;
                        height: 28px;
                        width: 200px;
                        text-align: center;
                        &:first-child {
                            margin-bottom: 14px;
                        }
                        @include transition();
                        &:hover {
                            background: #fafafa;
                        }
                    }
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    #menu-toggle {
        .enlaces-menu {            
            .noticias-menu {
                .noticia-menu-container {
                    .noticia-menu {
                        flex: 1 0 calc(90% - 20px);
                        max-width: calc(90% - 20px);
                    }
                    .noticia2 {
                        display: none;
                    }
                }
            }
        }
    
        .separador {
            background-color: #5bc6f8;
            height: 3px;
            width: 50px;
            margin-bottom: 1em;
        }
    
        .social {
            .social-content {
                .rrss {
                    margin-right: 20px;
                }
                .enlace-header {
                    margin-right: 20px;
                }
                .enlaces-content {
                    display: flex;
                    flex-wrap: wrap;
                    .enlaces-column {
                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    #menu-toggle {  
        .social {
            .social-content {
                .enlace-header {
                    width: 260px;
                }
                .enlaces-content {
                    .enlaces-column {
                        .enlace-header-small {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    #menu-toggle {    
        .enlaces-menu {
            .secciones {
                flex: 1 0 calc(20% - 20px);
                max-width: calc(20% - 20px);
            }
        
            .categorias {
                flex: 1 0 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
    
            .noticias-menu {
                flex: 1 0 calc(30% - 20px);
                max-width: calc(30% - 20px);
            }
        }
    }
}

@media (max-width: 860px) {
    #menu-toggle {  
        .social {
            display: none;
        }
    }
}

@media (max-width: 800px) {
    #menu-toggle {    
        .enlaces-menu {
            .categorias {
                display: none;
            }
    
            .noticias-menu {
                flex: 1 0 calc(80% - 20px);
                max-width: calc(80% - 20px);
                .noticia-menu-container {
                    .noticia-menu {
                        flex: 1 0 calc(45% - 20px);
                        max-width: calc(45% - 20px);
                    }
                    .noticia2 {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    #menu-toggle {    
        .enlaces-menu {
            .secciones {
                flex: 1 0 calc(30% - 20px);
                max-width: calc(30% - 20px);
            }
            .noticias-menu {
                flex: 1 0 calc(70% - 20px);
                max-width: calc(70% - 20px);
                .noticia-menu-container {
                    .noticia-menu {
                        flex: 1 0 calc(80% - 20px);
                        max-width: calc(80% - 20px);
                    }
                    .noticia2 {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    #menu {
        display: none;
    }
    #menu-toggle {   
        #cerrar-menu-toggle {
            i {
                font-size: 1em !important;
            }
        }
    }
}

@media (max-width: 400px) {
    #menu-toggle {    
        .enlaces-menu {
            .secciones {
                flex: 1 0 calc(95% - 20px);
                max-width: calc(95% - 20px);
                text-align: center;
            }
            .noticias-menu {
                display: none;
            }
        }
        .separador {
            margin: 0 auto 1em;
        }
    }
}

html,
body {
    margin: 0px;
    padding: 0px;
    font-size: $font-size-base !important;
    font-weight: $font-weight-normal;
    font-family: $font-family-body;
}

body {
    color: $color-body;
    display: flex;
    flex-direction: column;
    letter-spacing: .5px;
    max-width: 100%;
    overflow-x: hidden;
    &.edit_inline{
        background: $color-body-inline
    }
    &.no-publicada{
        background: $color-body-no-publicado
    }
}

.container {
    max-width: 1030px !important;
    padding-right: 30px;
    padding-left: 30px;
}

.libro {
    a {
        color: inherit;
        border-bottom: 1px solid rgba(102,204,255,.5);
        text-decoration: none;
        
        &:hover {
            color: inherit;
            border-bottom: 1px solid rgba(102,204,255,1);
            text-decoration: none;
        }
    }
}

.pagina {
    .ladillo {
        font-size: inherit;
        color: #59cdff;
        font-weight: bolder;
    }
}

@media (max-width: 767px) {
    .container {
        padding-right: 15px;
        padding-left: 15px;
    }
}

//SERIE PORTADA

.rw-new-container-general {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
}

.rw-new-container {
    position: relative;
}

.rw-new-swiper {
    width: 100%;
    overflow: hidden;
}

.swiper-wrapper {
    display: flex;
    padding: 0;
    flex-wrap: nowrap; /* Asegura que las imágenes no se apilen */
}

.rw-new-series-card {
    overflow: hidden;
    transition: transform 0.3s ease;
    text-align: center;
    box-sizing: border-box;
    margin: 0 5px; /* Agrega margen horizontal entre tarjetas */
    flex: 1 0 auto; /* Deja que las tarjetas tomen su propio tamaño automático */
    position: relative; /* Necesario para la info overlay */
    min-height: 250px; /* Altura mínima para la tarjeta, ajustada */
    background-color: #1e1e1e; /* Fondo para resaltar el espacio */
}

.rw-new-series-card:hover {
    transform: scale(1.05); /* Efecto de agrandamiento al hacer hover */
}

.rw-new-series-image {
    display: block;
    width: 90%; /* Ajusta el ancho de la imagen para que sea un poco más estrecha */
    height: 300px; /* Aumenta la altura de la imagen */
    object-fit: cover; /* Asegura que la imagen cubra el contenedor */
    transition: all 0.3s ease; /* Suaviza las transiciones */
    margin: 0 auto; /* Centra la imagen horizontalmente */
}

.rw-new-series-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.rw-new-series-card:hover .rw-new-series-info {
    opacity: 1;
}

.rw-new-series-title {
    font-size: 18px;
    margin: 0;
    font-weight: bold;
    color: #fff !important; /* Forzamos el color blanco */
}

.rw-new-series-description {
    font-size: 14px;
    margin: 15px 0 0;
    display: block;
    color: #fff !important;
}

.rw-new-swiper-pagination .swiper-pagination-bullet-active {
    background: #fff;
}

.rw-new-swiper-button-next,
.rw-new-swiper-button-prev {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.rw-new-swiper-button-next:hover,
.rw-new-swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.rw-new-swiper-button-next {
    right: 10px;
}

.rw-new-swiper-button-prev {
    left: 10px;
}

.rw-new-short-hr {
    border: 0;
    border-top: 1px solid #fff;
    margin: 20px 0;
}

.rw-new-footer-description {
    font-size: 12px;
    text-align: center;
    color: #bbb;
    padding: 10px;
}

.rw-new-centered-text {
    text-align: center; /* Centra el texto horizontalmente */
    font-size: 18px; /* Establece el tamaño de la fuente a 18 píxeles */
    margin-left: 10px; /* Aplica un margen izquierdo de 10 píxeles */
    margin-right: 10px; /* Aplica un margen derecho de 10 píxeles */
}

//CSS PARA LAS ANLCAS
p[id^="rw-"] {
    margin: 0 !important;
    padding: 0 !important;
    display: block;
    line-height: normal;
    font-size: 0;
    width: 100%;
}

/* Opcional: Eliminar otros estilos no deseados */
p[id^="rw-"]:before,
p[id^="rw-"]:after {
    content: '';
    display: none;
}
